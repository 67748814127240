import React from "react";
import {
  Box,
  TextField,
  Typography,
  Tooltip,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/EditCalendar";
import Grid from "@mui/material/Grid2";
import styles from "./styles";
import { DateTime } from "luxon";
import { getChipColor } from "../Common/commonComponents";

const UpcomingPastAppointmentsMobile = ({
  filteredAppointments,
  selectedTab,
  searchClinic,
  loading,
  setSearchClinic,
  setOpenConfirmDelete,
  setOpenConfirmEdit,
  setSelectedRow,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.textFieldBox}>
        <TextField
          id="search-clinic"
          label="Search Clinics"
          variant="outlined"
          size="small"
          sx={styles.textField}
          value={searchClinic}
          onChange={(e) => setSearchClinic(e.target.value)}
        />
      </Box>

      <Box sx={styles.mobileCardsBox}>
        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        ) : filteredAppointments.length === 0 ? (
          <Typography align="center" sx={{ mt: 4 }}>
            No Appointments Available
          </Typography>
        ) : (
          <Grid container spacing={6} justifyContent="center">
            {filteredAppointments.map((appointment, index) => (
              <Card sx={styles.card}>
                <Chip
                  label={appointment.status}
                  sx={{
                    ...styles.chip,
                    backgroundColor: getChipColor(appointment.status),
                  }}
                />
                {/* Action Buttons */}
                {selectedTab === "Upcoming Appointments" && (
                  <Box sx={styles.cardActions}>
                    <Tooltip title="Reschedule">
                      <IconButton
                        onClick={() => {
                          setSelectedRow(appointment);
                          setOpenConfirmEdit(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Cancel">
                      <IconButton
                        color="error"
                        onClick={() => {
                          setSelectedRow(appointment);
                          setOpenConfirmDelete(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                <CardContent sx={{ paddingTop: "42px" }}>
                  <Box sx={styles.clinicBox}>
                    <Typography sx={styles.cardBoldText}>
                      {appointment.Clinic.name}
                    </Typography>
                    <Typography sx={styles.cardSubText}>
                      {appointment.Clinic.address}
                    </Typography>
                  </Box>
                  {/* Appointment Details */}
                  <Box sx={styles.appointmentBox}>
                    <Typography sx={styles.cardMainText}>
                      {DateTime.fromISO(appointment.date).toFormat(
                        "MMMM dd, yyyy"
                      )}
                    </Typography>
                    <Typography sx={styles.cardMainText}>
                      {appointment.startTime}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default UpcomingPastAppointmentsMobile;
