// Yup Validation
import * as yup from "yup";

// Phone validation
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhinModCheck from "Components/ModCheck/PhinModCheck";

// Luxon
import { DateTime } from "luxon";

const getHealthCardValidationSchema = (province) => {
  switch (province) {
    case "AB":
      return yup
        .string()
        .length(9, "ULI must be 9 digits long")
        .required("ULI number is required");
    case "SK":
      return yup
        .string()
        .length(9, "SKI must be 9 digits long")
        .required("SKI number is required");
    case "NT":
      return yup
        .string()
        .length(8, "NT N or D must be 8 digits long")
        .required("NT N or D is required");
    case "YT":
      return yup
        .string()
        .length(9, "YT Health must be 9 digits long")
        .required("YT Health is required");
    case "NU":
      return yup
        .string()
        .length(9, "Nunavut HC must be 9 digits long")
        .required("Nunavut HC is required");
    case "BC":
      return yup
        .string()
        .length(10, "BC Health must be 10 digits long")
        .required("BC Health is required");
    case "ON":
      return yup
        .string()
        .min(10, "OHIP must be at least 10 digits long")
        .max(12, "OHIP cannot exceed 12 digits long")
        .required("OHIP is required");
    case "NB":
      return yup
        .string()
        .length(9, "NB Health must be 9 digits long")
        .required("NB number is required");
    case "NS":
      return yup
        .string()
        .length(10, "NS Health must be 10 digits long")
        .required("NS number is required");
    case "PE":
      return yup
        .string()
        .length(8, "PEI Health must be 8 digits long")
        .required("PEI number is required");
    case "NL":
      return yup
        .string()
        .length(12, "NL Health must be 12 digits long")
        .required("NL number is required");
    case "QC":
      return yup
        .string()
        .length(12, "Health Insurance Number must be 12 digits long")
        .required("Health Insurance Number is required");
    default: // case: "MB"
      return yup
        .string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .test("phin-mod-check", "Invalid PHIN", PhinModCheck)
        .required("PHIN is required");
  }
};

export const patientValidationSchema = yup.object().shape({
  // Required Information
  firstName: yup
    .string()
    .matches(/^[ A-Za-z'.-]*$/, "First name must be valid")
    .max(30, "First name cannot exceed 30 characters")
    .required("First name is required"),

  lastName: yup
    .string()
    .matches(/^[ A-Za-z'.-]*$/, "Last name must be valid")
    .max(75, "Last name cannot exceed 75 characters")
    .required("Last name is required"),

  dob: yup
    .string()
    .nullable() // Allows proper handling of null values
    .required("Date of birth is required")
    .test("valid-date", "Please enter a valid date", (value) => {
      if (!value) return true;
      return DateTime.fromISO(value).isValid;
    })
    .test(
      "min-date",
      "Date of birth must be on or after 1900-01-01",
      (value) => {
        if (!value) return true;
        return DateTime.fromISO(value) >= DateTime.fromISO("1900-01-01");
      }
    )
    .test("max-date", "Date of birth cannot be in the future", (value) => {
      if (!value) return true;
      return DateTime.fromISO(value) <= DateTime.now();
    }),

  primaryPhone: yup
    .string()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, "CA");
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .nullable(false)
    .required("Primary phone number is required"),

  secondaryPhone: yup
    .string()
    .nullable()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (!value) return true;
      const phoneNumber = parsePhoneNumberFromString(value, "CA");
      return phoneNumber ? phoneNumber.isValid() : false;
    }),

  email: yup
    .string()
    .email("Please insert a valid email")
    .max(75, "Email cannot exceed 75 characters")
    .nullable(false)
    .required("Email is required.")
    .typeError("Email is required"),

  healthCardProvince: yup.string().required("Health Card Province is required"),

  registrationNumber: yup.string().when("healthCardProvince", {
    is: (value) => value === "MB",
    then: (schema) =>
      schema
        .matches(
          /^(?!0{6})[A-Za-z0-9]{6}$/,
          "Registration number must be 6 characters long"
        )

        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Registration Number is Required"),
    otherwise: (schema) => schema,
  }),

  healthCardNumber: yup
    .string()
    .when("healthCardProvince", (healthCardProvince, schema) => {
      return getHealthCardValidationSchema(healthCardProvince[0]);
    }),

  // Address
  address: yup
    .string()
    .max(75, "Address cannot exceed 75 characters")
    .required("Address is required"),

  city: yup
    .string()
    .max(30, "City cannot exceed 30 characters")
    .required("City is required"),

  province: yup.string().required("Province is required"),

  postalCode: yup
    .string()
    .required("Postal Code is required")
    .matches(
      /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
      "Must use valid letters and be in the format 'R1A 1A1'"
    ),
});
