import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";
import { selectUserEmail } from "Store/AuthSlice";

// Material UI
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  DialogActions,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

// Components
import { AuthFooter } from "Components/Authentication/Components/AuthFooter";
import { OTP } from "Components/Authentication/Components/OTP";

// Formik
import { useFormik } from "formik";
import * as yup from "yup";

// Resource
import {
  sendVerificationCode,
  sendVerificationCodeAdmin,
  verifyCode,
  verifyCodeAdmin,
} from "API/Resource/verify";

// Styles
import { styles } from "./styles";

// Constants
import { emeraldGreen, terracotta } from "Constants/ColourConstants";
import { TEN_MINUTES } from "Constants/TimeConstants";

// Validation schema
const validationSchema = yup.object({
  code: yup
    .string("Enter verification code")
    .length(6, "Verification code must be 6 digits")
    .required("Verification code is required"),
});

// Main Component
const MFA = ({ open, onClose, onVerify, isAdmin = false }) => {
  const [step, setStep] = useState(isAdmin ? 2 : 1);
  const [method, setMethod] = useState("PHONE");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const patient = useSelector(selectPatient);
  const userEmail = useSelector(selectUserEmail);

  const dispatch = useDispatch();

  const email = isAdmin ? userEmail : patient?.maskedEmail;
  const number = isAdmin ? null : patient?.maskedPrimaryPhone;

  const handleSendCodeAdmin = useCallback(async () => {
    try {
      setIsSendingCode(true);
      await sendVerificationCodeAdmin(userEmail);
      sessionStorage.setItem("adminMFA", "sent");
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    } finally {
      setIsSendingCode(false);
    }
  }, [dispatch, userEmail]);

  const initAdminMFA = useCallback(async () => {
    if (sessionStorage.getItem("adminMFA") !== "sent") {
      handleSendCodeAdmin();
    }
    setMethod("EMAIL");
    setStep(2);
  }, [handleSendCodeAdmin]);

  const initPatientMFA = useCallback(() => {
    if (sessionStorage.getItem(`2FA${patient.id}`) === "sent") {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [patient.id]);

  // Send verification code logic
  const handleSendCodePatient = async () => {
    try {
      setIsSendingCode(true);
      await sendVerificationCode(patient.id, method);
      dispatch(
        openSnackbar({
          message: "Verification code successfully sent!",
          severity: "success",
        })
      );
      sessionStorage.setItem(`2FA${patient.id}`, "sent");
      setTimeout(() => {
        if (sessionStorage.getItem(`2FA${patient.id}`) === "sent") {
          sessionStorage.removeItem(`2FA${patient.id}`);
          setStep(1);
        }
      }, TEN_MINUTES);
      setStep(2); // Proceed to enter verification code
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    } finally {
      setIsSendingCode(false);
    }
  };

  // Resend verification code
  const handleResendCode = async () => {
    if (isAdmin) {
      await handleSendCodeAdmin();
    } else {
      await handleSendCodePatient();
    }
  };

  const handleVerifyCodeAdmin = async (code) => {
    await verifyCodeAdmin(userEmail, code);
    sessionStorage.setItem("adminMFA", "verified");
  };

  const handleVerifyCodePatient = async (code) => {
    await verifyCode(patient.id, method, code);
    sessionStorage.setItem(`2FA${patient.id}`, "verified");
  };

  // Verify the code entered by the user
  const handleVerifyCode = async (values) => {
    try {
      setIsVerifyingCode(true);
      if (isAdmin) {
        await handleVerifyCodeAdmin(values.code);
      } else {
        await handleVerifyCodePatient(values.code);
      }
      dispatch(
        openSnackbar({
          message: "Verification successful!",
          severity: "success",
        })
      );
      onVerify();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    } finally {
      setIsVerifyingCode(false);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      initAdminMFA();
    } else {
      initPatientMFA();
    }
  }, [isAdmin, initAdminMFA, initPatientMFA]);

  const MethodContent = () => {
    return (
      <>
        <DialogContent sx={styles.dialogContent}>
          <DialogContentText sx={styles.dialogContentText}>
            To proceed, how should we verify it's you?
          </DialogContentText>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            sx={styles.radioGroup}
            value={method}
            name="radio-buttons-group"
            onChange={(e) => setMethod(e.target.value)}
          >
            <FormControlLabel
              value="PHONE"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: terracotta,
                    },
                  }}
                />
              }
              label={`Text a code to ${number}`}
            />
            <FormControlLabel
              value="EMAIL"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: terracotta,
                    },
                  }}
                />
              }
              label={`Email a code to ${email}`}
            />
          </RadioGroup>
          <AuthFooter showCopyright={false} />
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="outlined" onClick={onClose} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={styles.submitButton}
            onClick={handleSendCodePatient}
            disabled={isSendingCode}
          >
            {isSendingCode ? "Sending..." : "Continue"}
          </Button>
        </DialogActions>
      </>
    );
  };

  const CodeContent = () => {
    const contentText = `A verification code has been sent to ${
      method === "EMAIL" ? email : number
    }. Please enter it below.`;

    const formik = useFormik({
      initialValues: {
        code: "",
      },
      validationSchema,
      onSubmit: (values) => {
        handleVerifyCode(values);
      },
    });

    return (
      <>
        <DialogContent sx={styles.dialogContent}>
          <DialogContentText sx={styles.dialogContentText}>
            {contentText}
          </DialogContentText>
          <OTP
            setCode={formik.setFieldValue}
            resendCode={handleResendCode}
            values={formik.values}
            handleChange={formik.handleChange("code")}
            errors={formik.errors}
            touched={formik.touched}
          />
          <AuthFooter showCopyright={false} />
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button variant="outlined" onClick={onClose} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={styles.submitButton}
            onClick={formik.handleSubmit}
            disabled={isVerifyingCode}
          >
            Submit
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={isAdmin ? null : onClose}
      sx={styles.dialogPaper}
    >
      <DialogTitle color={emeraldGreen} sx={styles.dialogTitle}>
        {step === 2 && !isAdmin && (
          <Button
            variant="contained"
            sx={styles.backButton}
            onClick={() => {
              setStep(1);
            }}
          >
            <ArrowBack sx={styles.backArrow} />
            BACK
          </Button>
        )}
        SECURITY VERIFICATION
      </DialogTitle>
      {step === 1 ? <MethodContent /> : <CodeContent />}
    </Dialog>
  );
};

export default MFA;
