import {
  terracotta,
  emeraldGreen,
  offWhite,
  white,
  grey,
} from "../../../../Constants/ColourConstants";

export const styles = {
  location: { display: "flex", alignItems: "center" },
  joinWaitlistButton: {
    borderRadius: "50px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    width: "100%",
  },
  bookViewMoreBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  bookButton: {
    flex: 1,
    width: "80%",
    borderRadius: "50px",
    padding: "8px 16px",
    backgroundColor: terracotta,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  viewMoreButton: {
    flex: 1,
    borderRadius: "50px",
    padding: "8px 16px",
    width: "80%",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  container: {
    backgroundColor: offWhite,
    minHeight: "100vh",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    boxSizing: "border-box",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    boxSizing: "border-box",
    flexWrap: "wrap",
    px: {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 0,
    },
    maxWidth: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: "80%",
    },
  },
  headerBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "100%",
  },
  patientNameBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    ml: { xs: 2, sm: 2, md: 0 },
    wordBreak: "break-word",
  },
  patientName: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    color: emeraldGreen,
  },
  header: {
    color: emeraldGreen,
    font: "Roboto",
    fontSize: {
      xs: "16px",
      sm: "16px",
      md: "20px",
    },
    fontWeight: "bold",
    lineHeight: "26px",
  },
  backButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "1.5rem",
    width: "100%",
  },
  flexStartContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  backButton: {
    backgroundColor: terracotta,
    width: "100px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2%",
  },
  backArrow: {
    marginRight: "8px",
  },
  backButtonText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeight: "medium",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    maxWidth: "1000px",
    margin: "0 auto",
  },
  tabs: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3rem",
    "@media (max-width: 850px)": {
      marginBottom: "2rem",
    },
    color: "rgba(0, 0, 0, 0.6)",
    "& .Mui-selected": { color: terracotta },
  },
  tabIndicator: {
    backgroundColor: terracotta,
  },
  fullWidthBox: {
    width: "100%",
    "@media (max-width: 1375px)": {
      marginRight: 0,
      marginLeft: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  availableAppointmentsPaper: {
    width: "100%",
    maxWidth: "100%",
    padding: 2,
    borderRadius: 2,
  },
  upcomingPastPaper: {
    width: "100%",
    maxWidth: "100%",
    padding: 2,
    marginLeft: -2,
    borderRadius: 2,
  },
  textFieldBox: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textField: {
    width: "20%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "darkgray",
      },
      "&.Mui-focused fieldset": {
        borderColor: terracotta,
      },
    },
    "& .MuiInputLabel-root": {
      color: "gray",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: terracotta,
    },
  },
  dataGridContainer: {
    height: "calc(100% - 80px)",
    width: "100%",
    overflowY: "auto",
  },
  dataGrid: {
    width: "100%",
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      color: emeraldGreen,
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      color: "#000",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row.Mui-selected:hover": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row": {
      position: "relative",
      borderRadius: "12px",
    },
    "& .MuiDataGrid-scrollbar": {
      display: "block",
    },
    "& .MuiCircularProgress-root": {
      color: emeraldGreen,
    },
  },
  searchFieldBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  truncatedText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },

  // Confirm Delete Modal Styles
  confirmDeleteOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1300,
  },
  confirmDeleteModalContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    width: "30%",
    minWidth: "315px",
    padding: 3,
    position: "relative",
  },
  confirmDeleteMessage: {
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "24px",
    textAlign: "center",
  },
  confirmDeleteActionButtons: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginTop: "16px",
  },
  confirmDeleteCancelButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  confirmDeleteOkButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
    "&.Mui-disabled": {
      backgroundColor: terracotta,
      color: "white",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: white,
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      width: "30%",
      minWidth: "315px",
      padding: 1,
    },
  },
  dialogText: {
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "center",
  },
  typography: {
    fontSize: 14,
    textAlign: "left",
    color: grey,
  },
  mobileCardsBox: {
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  mobileCards: {
    width: "300px",
    height: "350px",
    borderRadius: "0.7rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  mobileBoldText: {
    fontWeight: "bold",
    color: emeraldGreen,
  },
  mobileMainText: {
    fontSize: 18,
    fontWeight: "bold",
    color: emeraldGreen,
  },
  mobileSubText: {
    fontSize: 14,
    color: grey,
  },
  cardsMainBox: {
    width: "100%",
  },
  cardClinicBox: {
    height: "100px",
  },
  loadingBackdrop: {
    color: emeraldGreen,
    zIndex: 2000,
  },
  actionsBox: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
};
