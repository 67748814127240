// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectPatient } from "Store/PatientSlice";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import TimeIcon from "@mui/icons-material/AccessTime";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import ClinicIcon from "@mui/icons-material/Business";
import LocationIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";

// Styles
import { styles } from "./styles";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { mdiCloseCircleOutline } from "@mdi/js";
import { emeraldGreen, terracotta } from "Constants/ColourConstants";

// Resources
import { rescheduleAppointment } from "API/Resource/appointment";

// Libraries
import { DateTime } from "luxon";

const EditAppointmentModal = (props) => {
  const {
    openModal,
    closeModal,
    newAppointment,
    currentAppointment,
    refreshAppointments,
    loadingRefresh,
  } = props;
  const [loading, setLoading] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [response, setResponse] = useState("");
  const [isError, setIsError] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const patient = useSelector(selectPatient);

  const navigate = useNavigate();

  const handleEdit = async () => {
    setLoading(true);
    try {
      await rescheduleAppointment(newAppointment, currentAppointment);
      setResponse("Appointment Successfully Rescheduled!");
    } catch (error) {
      const errorMessages = {
        APPOINTMENT_SLOT_UNAVAILABLE:
          "This appointment slot is no longer available.",
        APPOINTMENT_TIME_PASSED: "The selected appointment time has passed.",
        APPOINTMENT_CANCELLED:
          "The selected appointment has been updated by the clinic and is no longer available for rescheduling. Please allow up to 30 minutes for the updates to reflect.",
      };
      const defaultErrorMessage =
        "Oops! Something went wrong while rescheduling your appointment.";

      const errorMessage = errorMessages[error.message] || defaultErrorMessage;
      setResponse(errorMessage);
      setIsError(true);
      if (error.message === "APPOINTMENT_CANCELLED") {
        setCancelled(true);
      }
    } finally {
      setLoading(false);
      setOpenResponse(true);
    }
  };

  const ResponseDialogContent = () => {
    const handleClickOK = () => {
      if (isError && !cancelled) {
        refreshAppointments();
      } else {
        navigate("/");
      }
    };
    return (
      <DialogContent>
        <Box sx={styles.responseIcon}>
          <Icon
            path={isError ? mdiCloseCircleOutline : mdiCheckCircleOutline}
            size={6}
            color={isError ? terracotta : emeraldGreen}
          />
        </Box>
        <Typography sx={styles.responseMessage}>
          {response}
          {isError && !cancelled && (
            <>
              <br />
              Please click OK to refresh the available appointments.
            </>
          )}
        </Typography>

        <Box sx={styles.responseButton}>
          <Button
            onClick={handleClickOK}
            variant="contained"
            sx={styles.confirmButton}
            disabled={loadingRefresh}
          >
            {loadingRefresh ? (
              <>
                <CircularProgress size={14} sx={styles.loading} />
                Refreshing
              </>
            ) : (
              "OK"
            )}
          </Button>
        </Box>
      </DialogContent>
    );
  };

  const RescheduleDialogContent = () => {
    return (
      <>
        <DialogTitle sx={styles.title}>RESCHEDULE APPOINTMENT</DialogTitle>
        <DialogContent>
          <Box sx={styles.appointmentComparison}>
            {/* Current Appointment */}
            <Box sx={styles.appointmentBox}>
              <Typography variant="h6">Current Appointment</Typography>
              <Box sx={styles.appointmentDetails}>
                <Box sx={styles.detailItem}>
                  <PersonIcon sx={styles.icon} />
                  <Typography>
                    {patient.firstName} {patient.lastName}
                  </Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <TimeIcon sx={styles.icon} />
                  <Typography>{currentAppointment.startTime}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <CalendarIcon sx={styles.icon} />
                  <Typography>
                    {DateTime.fromISO(currentAppointment.date).toFormat(
                      "MMM d, yyyy"
                    )}
                  </Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <ClinicIcon sx={styles.icon} />
                  <Typography>{currentAppointment.Clinic.name}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <LocationIcon sx={styles.icon} />
                  <Typography>{currentAppointment.Clinic.address}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <StarIcon sx={styles.icon} />
                  <Typography>{currentAppointment.type}</Typography>
                </Box>
              </Box>
              <Box sx={styles.actionButtons}>
                <Button
                  onClick={closeModal}
                  variant="outlined"
                  disabled={loading}
                  sx={styles.currentButton}
                >
                  Keep Current
                </Button>
              </Box>
            </Box>

            {/* New Appointment */}
            <Box sx={styles.appointmentBox}>
              <Typography variant="h6">New Appointment</Typography>
              <Box sx={styles.appointmentDetails}>
                <Box sx={styles.detailItem}>
                  <PersonIcon sx={styles.icon} />
                  <Typography>
                    {patient.firstName} {patient.lastName}
                  </Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <TimeIcon sx={styles.icon} />
                  <Typography>{newAppointment.startTime}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <CalendarIcon sx={styles.icon} />
                  <Typography>
                    {DateTime.fromISO(newAppointment.date).toFormat(
                      "MMM d, yyyy"
                    )}
                  </Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <ClinicIcon sx={styles.icon} />
                  <Typography>{newAppointment.clinicName}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <LocationIcon sx={styles.icon} />
                  <Typography>{newAppointment.address}</Typography>
                </Box>
                <Box sx={styles.detailItem}>
                  <StarIcon sx={styles.icon} />
                  <Typography>{newAppointment.type}</Typography>
                </Box>
              </Box>
              <Box sx={styles.actionButtons}>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  disabled={loading}
                  sx={styles.confirmButton}
                >
                  {loading ? (
                    <>
                      <CircularProgress size={14} sx={styles.loading} />
                      Rescheduling
                    </>
                  ) : (
                    "Reschedule"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </>
    );
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      maxWidth="md"
      fullWidth={!openResponse}
    >
      {openResponse ? <ResponseDialogContent /> : <RescheduleDialogContent />}
    </Dialog>
  );
};

export default EditAppointmentModal;
