import "./App.css";

// React
import { BrowserRouter as Router } from "react-router-dom";

// Components
import AuthContainer from "./Components/Authentication/AuthContainer";
// import Maintenance from "Components/Maintenance";
import NotificationSnackbar from "Components/NotificationSnackbar";
import ScrollToTop from "Components/Scroll/ScrollToTop";

// Material UI
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

import {
  alertSuccess,
  alertError,
  alertWarning,
  alertInfo,
} from "Constants/ColourConstants";

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: alertSuccess,
        },
        standardError: {
          backgroundColor: alertError,
        },
        standardWarning: {
          backgroundColor: alertWarning,
        },
        alertInfo: {
          backgroundColor: alertInfo,
        },
      },
    },
  },
});

const App = () => {
  return (
    // <Maintenance />
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <ScrollToTop />
            <NotificationSnackbar />
            <AuthContainer />
          </Router>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
