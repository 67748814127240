import { emeraldGreen, terracotta, white } from "Constants/ColourConstants";

export const styles = {
  title: {
    color: emeraldGreen,
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "16px",
    textAlign: "left",
  },
  appointmentComparison: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    flexDirection: { xs: "column", sm: "column", md: "row" },
  },
  appointmentBox: {
    flex: 1,
    backgroundColor: "#f7f7f7",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e0e0e0",
  },
  appointmentDetails: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "16px",
    marginLeft: ".5rem",
    marginBottom: "24px",
    textAlign: "left",
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    width: "100%",
    wordBreak: "break-word",
  },
  icon: {
    color: emeraldGreen,
    marginRight: "8px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginTop: "16px",
  },
  currentButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "150px",
  },
  confirmButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "150px",
  },
  loading: {
    color: white,
    marginRight: "8px",
  },
  responseIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "18px",
  },
  responseMessage: {
    color: "black",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "24px",
    textAlign: "center",
  },
  responseButton: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginTop: "16px",
  },
};
