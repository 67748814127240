// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Amplify
import { signOut } from "aws-amplify/auth";

// Material UI
import { Toolbar, IconButton, Typography, Button } from "@mui/material";
import { MenuRounded } from "@mui/icons-material";

// Styles
import { styles, StyledAppBar } from "./styles";

const NavigationBar = ({ open, handleOpen }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      window.location.reload();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  return (
    <StyledAppBar position="fixed" open={open} sx={styles.navBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpen}
          edge="start"
          sx={{
            marginRight: 5,
            display: open ? "none" : "block",
          }}
        >
          <MenuRounded />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          onClick={handleGoHome}
          sx={styles.title}
        >
          Admin Portal
        </Typography>
        <Button
          variant="contained"
          size={isMobile ? "small" : "medium"}
          onClick={handleSignOut}
          sx={styles.signOutButton}
        >
          Sign Out
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavigationBar;
