// React
import React, { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Styles
import { styles } from "./styles";

// MUI
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Resources
import { deactivatePatient } from "API/Resource/patient";

const DeactivatePatientModal = ({ open, patient, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeactivatePatient = async () => {
    try {
      setLoading(true);
      await deactivatePatient(patient.id);
      setLoading(false);
      onConfirm();
      dispatch(
        openSnackbar({
          message: "Patient successfully deactivated!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitle}>Deactivate Patient</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to <strong>deactivate </strong>
          {patient.firstName} {patient.lastName} ?
        </DialogContentText>
        <DialogContentText color="error">
          All upcoming appointments will be cancelled.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={styles.cancelButtonModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeactivatePatient}
          variant="contained"
          sx={styles.submitButtonModal}
          disabled={loading}
        >
          {loading ? (
            <>
              <CircularProgress size={"24px"} sx={styles.loading} />
              Deactivating
            </>
          ) : (
            "DEACTIVATE"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivatePatientModal;
