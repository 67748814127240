// React
import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// Amplify
import { signOut } from "aws-amplify/auth";

// Material UI
import { Box } from "@mui/material";

// Components
import AdminHome from "../AdminHome";
import ClinicManagement from "../ClinicManagement";
import NavigationBar from "../Navigation/NavigationBar";
import NavigationDrawer from "../Navigation/NavigationDrawer";
import { DrawerHeader } from "../Navigation/NavigationDrawer/styles";
import ClinicInfo from "../ClinicInfo";
import ClientInfo from "../ClientInfo";
import ClientManagement from "../ClientManagement";
import MFA from "Components/MFA/MFA";

// Styles
import { styles } from "./styles";

const AdminPortal = () => {
  const signInMFA = sessionStorage.getItem("adminMFA");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ignoreClickAway, setIgnoreClickAway] = useState(false);
  const [openSignInMFA, setOpenSignInMFA] = useState(
    !signInMFA || signInMFA === "sent"
  );

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setIgnoreClickAway(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMFASuccess = () => {
    setOpenSignInMFA(false);
    sessionStorage.setItem("adminMFA", "verified");
    navigate("/");
  };

  const handleMFACancel = async () => {
    await signOut();
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <Box sx={styles.portalBox}>
      {openSignInMFA ? (
        <>
          <MFA
            isAdmin={true}
            open={openSignInMFA}
            onClose={handleMFACancel}
            onVerify={handleMFASuccess}
          />
        </>
      ) : (
        <>
          <NavigationBar open={drawerOpen} handleOpen={handleDrawerOpen} />
          <NavigationDrawer
            open={drawerOpen}
            handleClose={handleDrawerClose}
            ignoreClickAway={ignoreClickAway}
            setIgnoreClickAway={setIgnoreClickAway}
          />
          <Box component="main" sx={styles.mainComponent}>
            <DrawerHeader />
            <Routes>
              <Route path="/" element={<AdminHome />} />
              <Route path="/ClinicManagement" element={<ClinicManagement />} />
              <Route path="/AddClinic" element={<ClinicInfo />} />
              <Route path="/EditClinic" element={<ClinicInfo />} />
              <Route path="/ClientManagement" element={<ClientManagement />} />
              <Route path="/AddClient" element={<ClientInfo />} />
              <Route path="/EditClient" element={<ClientInfo />} />
            </Routes>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdminPortal;
