// Colours
import {
  terracotta,
  emeraldGreen,
  offWhite,
  white,
} from "../../Constants/ColourConstants";

export const styles = {
  pageTitle: {
    color: emeraldGreen,
    fontFamily: "Roboto, sans-serif",
    fontSize: 25,
    fontWeight: "medium",
  },
  titleBox: {
    maxWidth: "1200px",
    paddingTop: "3vh",
    paddingBottom: "3vh",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
  },
  background: {
    minHeight: "100vh",
    backgroundColor: offWhite,
    padding: "2%",
  },
  cardBackground: {
    backgroundColor: white,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "Auto",
    marginBottom: "2vh",
    paddingBottom: "40px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
  },
  cardTitle: {
    paddingTop: "3vh",
    paddingBottom: "3vh",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "2%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    color: emeraldGreen,
    fontFamily: "Roboto, sans-serif",
    fontSize: 20,
    fontWeight: "medium",
  },
  inputTextField: {
    width: "90%",
    height: "50px",
    textAlign: "left",
  },
  select: {
    width: "90%",
    height: "50px",
  },
  multiLineTextField: {
    width: "95%",
    minHeight: "50px",
  },
  buttonBox: {
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  cancelButton: {
    backgroundColor: white,
    color: emeraldGreen,
    borderRadius: 30,
    borderColor: emeraldGreen,
    border: 1,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.50)",
  },
  submitButton: {
    backgroundColor: terracotta,
    borderRadius: 30,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.50)",
  },
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 18,
    fontWeight: "medium",
  },
  backButton: {
    backgroundColor: terracotta,
    width: "100%",
    borderRadius: "50px",
    justifyContent: "space-between",
  },
  headerBox: {
    width: "80%",
  },
  datePicker: {
    width: "90%",
  },
  // confirm modal

  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    width: "400px",
    padding: 3,
    position: "relative",
    zIndex: 1400,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headingModal: {
    color: emeraldGreen,
    fontWeight: "medium",
    textTransform: "uppercase",
  },
  infoTextModal: {
    fontWeight: 400,
    textAlign: "left",
    width: "100%",
    mb: ".5rem",
  },
  buttonContainerModal: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    gap: 2,
    mt: 2,
  },
  cancelButtonModal: {
    width: "150px",
    borderRadius: "50px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  submitButtonModal: {
    width: "150px",
    borderRadius: "50px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: white,
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      width: "50%",
      minWidth: "315px",
      padding: 1,
    },
  },
  okButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: white,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  confirmContent: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  confirmDetailsBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  confirmDetailBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    width: "100%",
    wordBreak: "break-word",
  },
  dialogTitle: {
    color: emeraldGreen,
    textTransform: "uppercase",
  },
  loading: {
    marginTop: "5%",
    color: emeraldGreen,
  },
};
