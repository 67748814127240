import { post } from "../../Utils/HTTPMethods";

/**
 * @param {string} patientId - Patient ID.
 * @param {string} method - Method of contact (PHONE or EMAIL).
 */
export const sendVerificationCode = async (patientId, method) => {
  return await post("QBookingResource", `/verify`, {
    action: "sendCode",
    patientId,
    method,
  });
};

/**
 * @param {string} patientId - Patient ID.
 * @param {string} method - Method of contact (PHONE or EMAIL).
 * @param {string} code - Verification code.
 */
export const verifyCode = async (patientId, method, code) => {
  return await post("QBookingResource", `/verify`, {
    action: "verifyCode",
    patientId,
    method,
    verificationCode: code,
  });
};

/**
 * @param {string} email - Admin user email.
 */
export const sendVerificationCodeAdmin = async (email) => {
  return await post("QBookingResource", `/verify`, {
    action: "sendCode",
    group: "ADMIN",
    email,
  });
};

/**
 * @param {string} email - Admin user email.
 * @param {string} code - Verification code.
 */
export const verifyCodeAdmin = async (email, code) => {
  return await post("QBookingResource", `/verify`, {
    action: "verifyCode",
    group: "ADMIN",
    email,
    verificationCode: code,
  });
};
