// React
import React from "react";

// MUI
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

// Styles
import { styles } from "./styles";

const ActivatePatientModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="activated-patient-dialog"
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.dialogTitle}>Patient Activated</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A deactivated patient with the same health card number, registration
          number and date of birth already exists in your account. This patient
          has been activated.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} sx={styles.okButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivatePatientModal;
