import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import {
  alertSuccess,
  alertError,
  disabledText,
} from "Constants/ColourConstants";
import styles from "./styles";

export const getChipColor = (status) => {
  switch (status.toLowerCase()) {
    case "booked":
      return alertSuccess;
    case "cancelled":
      return alertError;
    default:
      return disabledText;
  }
};

export const ConfirmDeleteModal = ({ open, onClose, onConfirm, loading }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
    <DialogTitle sx={styles.confirmTitle}>Cancel</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to <strong>cancel</strong> this appointment?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant="outlined"
        disabled={loading}
        sx={styles.noButton}
      >
        No
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={loading}
        sx={styles.yesButton}
      >
        {loading ? (
          <>
            <CircularProgress size={"14px"} sx={styles.loading} />
            Cancelling
          </>
        ) : (
          "Yes"
        )}
      </Button>
    </DialogActions>
  </Dialog>
);

export const ErrorDialog = ({ open, onClose, errorMessage }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
    <DialogTitle sx={styles.errorTitle}>Error</DialogTitle>
    <DialogContent>
      <DialogContentText>{errorMessage}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={onClose}
        sx={styles.confirmOkNoButton}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export const ConfirmEditModal = ({ open, onClose, onConfirm, loading }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
    <DialogTitle sx={styles.confirmTitle}>Reschedule</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to <strong>reschedule</strong> this appointment?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant="outlined"
        disabled={loading}
        sx={styles.noButton}
      >
        No
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={loading}
        sx={styles.yesButton}
      >
        {loading ? (
          <>
            <CircularProgress size={"14px"} sx={styles.loading} />
            Searching
          </>
        ) : (
          "Yes"
        )}
      </Button>
    </DialogActions>
  </Dialog>
);
