import { get, post, patch, put } from "../../Utils/HTTPMethods";

export const getAppointmentsByPatientId = async (patientId) => {
  return await get("QBookingResource", `/appointment`, { patientId });
};

/**
 * @param {String} ageGroup - one from  ALL, YOUNG, ADULT
 * @param {String} reasonForVisit - the reason for the visit (clinic.appointmentSuggestions[].type)
 * @param {String} clinicId - the clinic id (to get appointments for a specific clinic)
 * @return - all free appointments for all clinics and reason for visit types
 */
export const getAvailableAppointments = async (
  ageGroup = "ALL",
  reasonForVisit = null,
  clinicId = null
) => {
  const params = {
    ageGroup,
    ...(reasonForVisit && { reasonForVisit }),
    ...(clinicId && { clinicId }),
  };
  return await get("QBookingResource", `/appointment`, params);
};

export const bookAppointment = async (appointment) => {
  return await post("QBookingResource", `/appointment`, appointment);
};

export const cancelAppointment = async (id) => {
  return await patch("QBookingResource", `/appointment`, {
    id,
    status: "CANCELLED",
    action: "cancel",
  });
};

export const rescheduleAppointment = async (
  newAppointment,
  currentAppointment
) => {
  return await put("QBookingResource", `/appointment`, {
    newAppointment,
    currentAppointment,
    action: "reschedule",
  });
};
