// Colours
import { emeraldGreen, terracotta } from "Constants/ColourConstants";

export const styles = {
  pageBox: {
    maxWidth: "1100px",
    ml: "auto",
    mr: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    my: 2,
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 20,
    color: emeraldGreen,
    textAlign: "left",
  },
  contentBox: {
    width: "100%",
    minHeight: 500,
    border: "none",
    background: "white",
    borderRadius: 4,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    paddingTop: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  datagrid: {
    width: "100%",
    flexGrow: 1,
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      color: emeraldGreen,
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      color: "#000",
      "@media (max-width: 850px)": {
        marginRight: 0,
        marginLeft: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row.Mui-selected:hover": {
      backgroundColor: "#F6D9C8",
    },
    "& .MuiDataGrid-row": {
      position: "relative",
      borderRadius: "12px",
    },
    "& .MuiDataGrid-scrollbar": {
      display: "block",
    },
  },
  actionsBox: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "cetner",
  },
  cancelButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
    width: 125,
  },
  confirmButton: {
    borderRadius: 30,
    background: terracotta,
    width: 125,
  },
  authCodeContent: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  authCodeBox: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  clearAuthCodeButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
    width: 130,
    mx: "auto",
  },
  submitButton: {
    borderRadius: 30,
    background: terracotta,
    width: 125,
  },
  addButton: {
    borderRadius: 30,
    background: terracotta,
    width: 175,
  },
  attentionRequired: { color: terracotta, my: "auto" },
};
