// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Styles
import { styles } from "./styles";
import { emeraldGreen } from "Constants/ColourConstants";

// MUI
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  AlternateEmail,
  CalendarMonth,
  ContactEmergency,
  Home,
  Person,
  Phone,
} from "@mui/icons-material";

// Resources
import { updatePatient, createPatient } from "API/Resource/patient";

const ConfirmEditModal = (props) => {
  const {
    open,
    onClose,
    patientValues,
    setOpenActivatedDialog,
    setOpenDuplicatePHINDialog,
  } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (patientValues?.id) {
        await updatePatient(patientValues);
        navigate("/");
      } else {
        const createdPatient = await createPatient(patientValues);
        if (createdPatient.activated) {
          setOpenActivatedDialog(true);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.message === "DUPLICATE_ACTIVE_PHIN") {
        setOpenDuplicatePHINDialog(true);
        dispatch(
          openSnackbar({
            message: "PHIN already exists",
            severity: "error",
          })
        );
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={styles.headingModal}>Confirm Patient</DialogTitle>
      <DialogContent sx={styles.confirmContent}>
        <DialogContentText>
          Please verify all your details are correct.
        </DialogContentText>
        <Box sx={styles.confirmDetailsBox}>
          <Box sx={styles.confirmDetailBox}>
            <Person sx={{ color: emeraldGreen }} />
            <DialogContentText>
              {patientValues.firstName} {patientValues.lastName}
            </DialogContentText>
          </Box>
          <Box sx={styles.confirmDetailBox}>
            <CalendarMonth sx={{ color: emeraldGreen }} />
            <DialogContentText>{patientValues.dob}</DialogContentText>
          </Box>
          <Box sx={styles.confirmDetailBox}>
            <AlternateEmail sx={{ color: emeraldGreen }} />
            <DialogContentText>{patientValues.email}</DialogContentText>
          </Box>
          <Box sx={styles.confirmDetailBox}>
            <Phone sx={{ color: emeraldGreen }} />
            <DialogContentText>
              Primary: {patientValues.primaryPhone}
              {patientValues.secondaryPhone && (
                <>
                  <br /> Secondary: {patientValues.secondaryPhone}
                </>
              )}
            </DialogContentText>
          </Box>
          <Box sx={styles.confirmDetailBox}>
            <Home sx={{ color: emeraldGreen }} />
            <DialogContentText>
              {patientValues.address}, {patientValues.city},{" "}
              {patientValues.postalCode}, {patientValues.province}{" "}
            </DialogContentText>
          </Box>
          <Box sx={styles.confirmDetailBox}>
            <ContactEmergency sx={{ color: emeraldGreen }} />
            <DialogContentText>
              Province: {patientValues.healthCardProvince} <br />
              Health Card #: {patientValues.healthCardNumber} <br />
              {patientValues.registrationNumber && (
                <>Reg #: {patientValues.registrationNumber}</>
              )}
            </DialogContentText>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1, mr: 1 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={styles.cancelButtonModal}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={styles.submitButtonModal}
        >
          {loading ? (
            <CircularProgress size={"24px"} sx={{ color: "#FFFF" }} />
          ) : (
            "CONFIRM"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEditModal;
