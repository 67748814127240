// React
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Formik
import { FieldArray, Formik } from "formik";

// Material UI
import {
  Alert,
  Box,
  Typography,
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Add, ClearRounded, ExpandMore } from "@mui/icons-material";

// Styles
import { styles } from "./styles";

// Components
import { clientValidationSchema } from "Validation/ClientValidation";

// Resources
import { createClient, updateClient } from "API/Resource/client";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Libraries
import { isEqual } from "lodash";

// Form default values
const defaultValues = {
  name: "",
  domain: "",
  clinics: [],
  appointmentLocations: [],
  appointmentReasons: [],
  appointmentFilter: "",
};

const ClientInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const editClient = location.state?.client;

  const navigateToClientManagement = () => {
    navigate("/ClientManagement");
  };

  const handleClientSave = async (values) => {
    editClient
      ? await handleUpdateClient(values)
      : await handleCreateClient(values);
  };

  const handleCreateClient = async (values) => {
    try {
      await createClient(values);
      setConfirmationOpen(true);
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleUpdateClient = async (values) => {
    const changes = Object.keys(values).reduce((acc, key) => {
      if (!isEqual(values[key], editClient[key])) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    try {
      if (Object.keys(changes).length > 0) {
        await updateClient({
          id: editClient.id,
          ...changes,
        });
        setConfirmationOpen(true);
      } else {
        navigateToClientManagement();
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const ConfirmationDialog = ({ clientName }) => {
    return (
      <Dialog
        open={confirmationOpen}
        onSubmit={navigateToClientManagement}
        onClose={() => setConfirmationOpen(false)}
        sx={styles.dialog}
      >
        <DialogTitle sx={styles.dialogText}>Success!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Client <strong>{clientName}</strong> was successfully{" "}
            {editClient ? "updated" : "created"}.{" "}
          </DialogContentText>
          {editClient && (
            <Alert severity="warning">
              Please note that all clinics associated with this client may need
              to be updated manually.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmationOpen(false);
              navigateToClientManagement();
            }}
            sx={styles.mainButton}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={editClient ?? defaultValues}
      enableReinitialize={true}
      validationSchema={clientValidationSchema}
      onSubmit={(values) => {
        handleClientSave(values);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <ConfirmationDialog clientName={formik.values.name} />

          <Box sx={styles.pageBox}>
            <Typography sx={styles.title}>Client Information</Typography>

            {/* General Information */}
            <Container sx={styles.cardContainer}>
              <Typography sx={styles.title}>General</Typography>
              <TextField
                id="name"
                name="name"
                label="Name"
                type="text"
                variant="outlined"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                id="domain"
                name="domain"
                label="Domain"
                type="text"
                variant="outlined"
                fullWidth
                required
                value={formik.values.domain}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.domain && Boolean(formik.errors.domain)}
                helperText={formik.touched.domain && formik.errors.domain}
              />
            </Container>

            {/* Appointment Preferences */}
            <Container sx={styles.cardContainer}>
              <Typography sx={styles.title}>Appointments</Typography>
              {editClient && (
                <Alert severity="warning">
                  Updating these settings will require all clinics associated
                  with this client to be updated manually.
                </Alert>
              )}
              <FormControl
                sx={styles.formControl}
                error={
                  formik.touched.appointmentFilter &&
                  Boolean(formik.errors.appointmentFilter)
                }
              >
                <FormLabel id="appointment-filter-label" sx={styles.filterText}>
                  Filter appointments by:
                </FormLabel>
                <FormHelperText>
                  {formik.touched.appointmentFilter &&
                    formik.errors.appointmentFilter}
                </FormHelperText>

                <RadioGroup
                  aria-labelledby="appointment-filter-radio-group"
                  name="appointmentFilter"
                  value={formik.values.appointmentFilter}
                  onChange={formik.handleChange}
                  sx={styles.radioGroup}
                >
                  <FormControlLabel
                    value="LOCATION"
                    control={<Radio />}
                    label="Location"
                  />
                  <FormControlLabel
                    value="REASON"
                    control={<Radio />}
                    label="Reason"
                  />
                </RadioGroup>
              </FormControl>

              <Accordion
                defaultExpanded={
                  editClient?.appointmentFilter === "LOCATION" || !editClient
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="appointment-locations"
                  id="appointment-locations"
                >
                  <Typography sx={styles.subtitle}>Locations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormHelperText
                    error={
                      formik.touched.appointmentLocations &&
                      Boolean(formik.errors.appointmentLocations)
                    }
                  >
                    {!formik.values.appointmentLocations.length &&
                      formik.touched.appointmentLocations &&
                      formik.errors.appointmentLocations}
                  </FormHelperText>
                  <FieldArray
                    name="appointmentLocations"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.appointmentLocations.map(
                          (obj, index) => (
                            <Box key={index} sx={styles.filterRow}>
                              <TextField
                                label="Location"
                                name={`appointmentLocations[${index}]`}
                                type="text"
                                variant="outlined"
                                required={
                                  formik.values.appointmentFilter === "LOCATION"
                                }
                                value={
                                  formik.values.appointmentLocations[index]
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.appointmentLocations?.[
                                    index
                                  ] &&
                                  Boolean(
                                    formik.errors.appointmentLocations?.[index]
                                  )
                                }
                                helperText={
                                  formik.touched.appointmentLocations?.[
                                    index
                                  ] &&
                                  formik.errors.appointmentLocations?.[index]
                                }
                                sx={{ flex: 2 }}
                              />

                              <IconButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              >
                                <ClearRounded />
                              </IconButton>
                            </Box>
                          )
                        )}
                        <Fab
                          size="medium"
                          aria-label="add-appointment-location"
                          onClick={() => arrayHelpers.push("")}
                          sx={styles.fab}
                        >
                          <Add />
                        </Fab>
                      </>
                    )}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded={
                  editClient?.appointmentFilter === "REASON" || !editClient
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="appointment-reasons"
                  id="appointment-reasons"
                >
                  <Typography sx={styles.subtitle}>Reasons</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormHelperText
                    error={
                      formik.touched.appointmentReasons &&
                      Boolean(formik.errors.appointmentReasons)
                    }
                  >
                    {!formik.values.appointmentReasons.length &&
                      formik.touched.appointmentReasons &&
                      formik.errors.appointmentReasons}
                  </FormHelperText>
                  <FieldArray
                    name="appointmentReasons"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.appointmentReasons.map((obj, index) => (
                          <Box key={index} sx={styles.filterRow}>
                            <TextField
                              label="Reason"
                              name={`appointmentReasons[${index}]`}
                              type="text"
                              variant="outlined"
                              required={
                                formik.values.appointmentFilter === "REASON"
                              }
                              value={formik.values.appointmentReasons[index]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.appointmentReasons?.[index] &&
                                Boolean(
                                  formik.errors.appointmentReasons?.[index]
                                )
                              }
                              helperText={
                                formik.touched.appointmentReasons?.[index] &&
                                formik.errors.appointmentReasons?.[index]
                              }
                              sx={{ flex: 2 }}
                            />

                            <IconButton
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <ClearRounded />
                            </IconButton>
                          </Box>
                        ))}
                        <Fab
                          size="medium"
                          aria-label="add-appointment-reason"
                          onClick={() => arrayHelpers.push("")}
                          sx={styles.fab}
                        >
                          <Add />
                        </Fab>
                      </>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            </Container>

            <Box sx={styles.buttonsBox}>
              <Button
                variant="outlined"
                onClick={navigateToClientManagement}
                sx={styles.secondaryButton}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={styles.mainButton}>
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ClientInfo;
