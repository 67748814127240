// Colours
import {
  emeraldGreen,
  offWhite,
  deleteRed,
  terracotta,
  disabledText,
  grey,
  white,
} from "../../../../Constants/ColourConstants";

export const styles = {
  card: {
    width: "300px",
    height: "450px",
    borderRadius: "0.7rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  header: {
    height: "20%",
    backgroundColor: emeraldGreen,
    opacity: "50%",
  },
  logoBox: {
    position: "relative",
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 100,
    height: 100,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  logoBorder: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 110,
    height: 110,
    backgroundColor: offWhite,
    borderRadius: "50%",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  initials: {
    color: emeraldGreen,
    fontFamily: "Roboto, sans-serif",
    fontSize: 45,
    fontWeight: "medium",
  },
  grid: {
    paddingTop: "5%",
  },
  createIcon: {
    color: "#6B6B6B",
    height: 25,
    width: 25,
  },
  deleteIcon: {
    color: deleteRed,
    height: 25,
    width: 25,
  },
  name: {
    color: emeraldGreen,
    fontFamily: "Roboto, sans-serif",
    fontSize: 25,
    fontWeight: "medium",
    padding: "5%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  upcomingAppts: {
    color: terracotta,
    fontFamily: "Roboto, sans-serif",
    fontSize: 15,
    fontWeight: "medium",
  },
  apptList: {
    fontFamiily: "Roboto, sans-serif",
    fontSize: 14,
  },
  noApptText: {
    fontFamiily: "Roboto, sans-serif",
    fontSize: 14,
    color: disabledText,
  },
  apptGrid: {
    height: "100px",
    width: "100%",
    paddingBottom: "5%",
    paddingTop: "5%",
  },
  buttonContainer: {
    paddingTop: "2%",
  },
  secondaryButton: {
    color: emeraldGreen,
    borderColor: emeraldGreen,
    height: "30px",
    width: "100%",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 46,
  },
  primaryButton: {
    background: terracotta,
    borderRadius: 46,
    height: "42px",
    width: "83%",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  cardGrid: {
    justifyContent: "center",
  },
  cardBox: {
    maxWidth: "1000px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  noPatients: {
    color: grey,
    marginTop: "10%",
  },

  // Deactivate Patient Modal
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: white,
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      width: "50%",
      minWidth: "315px",
      padding: 1,
    },
  },
  dialogTitle: {
    color: emeraldGreen,
    textTransform: "uppercase",
  },
  cancelButtonModal: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  submitButtonModal: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  loading: {
    color: white,
    marginRight: "8px",
  },
};
