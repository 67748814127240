import {
  terracotta,
  grey,
  emeraldGreen,
  white,
} from "Constants/ColourConstants";

export const styles = {
  dialogPaper: {
    backgroundColor: white,
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    width: "50%",
    minWidth: "315px",
    padding: 1,
  },
  dialogContent: {
    textAlign: "center",
    padding: "20px 24px",
  },
  dialogTitle: {
    color: grey,
    fontWeight: 400,
    mb: 1,
  },
  dialogActions: {
    justifyContent: "center",
    gap: 2,
    paddingBottom: "16px",
  },
  noButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  yesButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  confirmOkNoButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },

  dialogText: {
    color: grey,
  },
  loading: {
    color: white,
    marginRight: "8px",
  },
  errorTitle: {
    color: terracotta,
    textTransform: "uppercase",
  },
  confirmTitle: {
    color: emeraldGreen,
    textTransform: "uppercase",
  },
};

export default styles;
